import React, { useState } from 'react';

const NoPath = () => {
  const [course, setCourse] = useState('');

  const handleClick = (e) => {
    if (e.key === 'Enter') {
      window.location.href = course;
    }
  }
  return (
    <div className='course_page'>
        <h1>This webpage is meant to serve as a quick, short, and easily remembered link that takes you to any course offered in UAEU that you'd like to learn more about.</h1>
        <h1>To use this webpage, simply enter its URL followed by the course code.</h1><br /><br />
        <h2>Examples:</h2>
        <h2><a href='https://course.uaeu.club/CSBP301'>https://course.uaeu.club/CSBP301</a></h2>
        <h2><a href='https://course.uaeu.club/SWEB450'>https://course.uaeu.club/SWEB450</a></h2><br />
        <h1>Or enter the course code below</h1><br />
        <input className='course_input' onChange={(e) => setCourse(e.target.value)}onKeyDown={handleClick} placeholder='HSS105'></input>
        <h3>Missing course? Email admin@uaeu.club</h3>
    </div>
  )
}

export default NoPath