import React, { useEffect, useState } from 'react';

import './link.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Redirect from './components/Redirect';
import NoRedirect from './components/NoRedirect';
import NoPath from './components/NoPath';
import Redirects from './Redirects.json';

const header = {
  headers: {
      Authorization: "Bearer " + process.env.REACT_APP_API_SECRET,
  },
};

const Link = () => {
  const [element, setElement] = useState()
  
  const location = window.location.href.split("/");
  let path = location[location.length - 1].toLowerCase();
  if (path.includes("?")) {
    path = path.split("?")[0];
  }

  useEffect(() => {
    if (Redirects[path]) {
      setElement(<Redirect Link={Redirects[path]} />)
    } else if (path === "") {
      setElement(<NoPath />)
    } else {
      setElement(<NoRedirect path={path} />)
      // const fetchUrl = async () => {
      //   fetch((process.env.NODE_ENV === "production")? "https://api.uaeu.club/" : "http://localhost:3000/", header)
      //   .then(async () => {
      //     const url = (process.env.NODE_ENV === "production")? "https://api.uaeu.club/redirects/v1/getRedirect/" : "http://localhost:3000/redirects/v1/getRedirect/";
      //     const response = await fetch(url + path, header);
      //     const data = await response.json();
      //     if (response.status === 200) {
      //       setElement(<Redirect Link={data.url}/>)
      //     } else if (response.status === 404) {
      //       setElement(<NoRedirect path={path} />)
      //     } else {
      //       setElement(<NoPath />)
      //     }
      //   })
      //   .catch((error) => {
      //     setElement(<Loading />)
      //   })
      // }
      // fetchUrl();
    }
  }, [path])

  return ((element)?
    <BrowserRouter>
      <Routes>
        <Route path={path} element={element}/>
      </Routes>
    </BrowserRouter>
    :
    <div className='loading'>
      <h1>Loading...</h1>
    </div>
  )
}

const Loading = () => {
  return (
    <div className='loading'>
      <h1>Loading...</h1><br />
      <h3>If you're stuck on this page then the server is probably burning. Please contact admin@uaeu.club</h3>
    </div>
  )
}

export default Link