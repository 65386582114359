import React from 'react';
import './Apply.css';
import csImg from '../../assets/cs.png';

const Apply = () => {
  return (
    <div className='apply'>
        <div className='apply_header'>
            <img src={csImg} alt='Computer Science Club Logo'/>
        </div>
        <div className='apply_form'>
        <iframe title="gform" src="https://docs.google.com/forms/d/e/1FAIpQLSfXxj1iQghrwAM3JrJxjFP22YUGlGUTY6PzDiR4VwGLRNmmfw/viewform?embedded=true" width="640" height="1024" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>
    </div>
  )
}

export default Apply