import React from 'react';

import './course.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Redirect from './components/Redirect';
import courses from './Courses.json';
import NoCourse from './components/NoCourse';
import NoPath from './components/NoPath';

const Course = () => {
  const courseRoutes = [];
  const location = window.location.href.split("/");
  let course = location[location.length - 1].toUpperCase();

  if (course.includes("?")) {
    course = course.split("?")[0];
  }

  if (courses[course]) {
    const link = `https://www.uaeu.ac.ae/en/catalog/courses/course_${courses[course]}`
    courseRoutes.push(<Route path={'/' + decodeURI(course)} element={<Redirect Link={link}/>}/>)
  } else {
    courseRoutes.push(<Route path={'/' + decodeURI(course)} element={<NoCourse course={decodeURI(course)}/>}/>)
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<NoPath />}/>
        {courseRoutes}
      </Routes>
    </BrowserRouter>
  )
}

export default Course