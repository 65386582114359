import React from 'react';

const NoPath = () => {
  return (
    <div className='no_redirect'>
        <h1>This is a custom, private link shortener</h1>
        <h1>Most links will redirect you to an official UAEU website or an unaffiliated but UAEU-related website</h1>
        <h2>E.g. a UAEU club's instagram account, google forms, useful resources, etc...</h2>
        <h3>If you would like to contact me regarding an issue, please send an email to admin@uaeu.club</h3>
    </div>
  )
}

export default NoPath