import React, { useEffect } from 'react';

import './CS.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Apply, Soon } from '../containers';

const CS = () => {
  
  useEffect(() => {
    // Define
    let link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    let embedTitle = document.querySelector("meta[property~='og:title']");
    let embedURL = document.querySelector("meta[property~='og:url']");
    let embedIcon = document.querySelector("meta[property~='og:image']");
    let embedTheme = document.querySelector("meta[name~='theme-color']");

    // Set values
    document.title = "CS Club"

    link.href = '/cs.ico';
    embedTitle.content = "CS Club"
    embedURL.content = "https://cs.uaeu.club"
    embedIcon.content = "/cs.png"
    embedTheme.content = "#00a6d6"
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Soon/>} />
        <Route path='/Apply' element={<Apply/>} />
      </Routes>
    </BrowserRouter>
  )
}

export default CS