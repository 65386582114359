import React from "react";
import { createRoot } from "react-dom/client";

import App from "./App";
import './index.css';
import CS from "./CS/CS";
import Link from "./Link/Link";
import Course from "./Course/Course";

const root = createRoot(document.getElementById('root'));

const location = window.location.host.split(".")[0];

if (location === "uaeu") {
    root.render(<App />);
} else if (location === "cs") {
    root.render(<CS />);
} else if (location === "link" || location === "links") {
    root.render(<Link />);
} else if (location === "course" || location === "courses") {
    root.render(<Course />);
} else {
    root.render(<App />);
}