import React from 'react';

const NoCourse = ({ course }) => {
  return (
    <div className='course_page'>
        <h1>No course with name {course}. Please double check and make sure you typed it correctly.</h1>
        <h3>If you believe this is a mistake, please contact admin@uaeu.club</h3>
    </div>
  )
}

export default NoCourse