import React from 'react';

const NoRedirect = ({ path }) => {
  return (
    <div className='no_redirect'>
        <h1>No link with path <p>/{path}</p>. Make sure you typed it correctly.</h1>
        <h3>If you believe this is a mistake, please contact admin@uaeu.club</h3>
    </div>
  )
}

export default NoRedirect