import React from 'react';
import './soon.css';
import logo from '../../assets/logo.svg';

const Header = () => {
  return (
    <div className='soon__container'>
      <div className='soon__container_content'>
        <img src={logo} alt='Application Logo'/>
        <h1>Coming soon!</h1>
      </div>
    </div>
  )
}

export default Header