import React from 'react';

import { Soon } from './containers';
import './App.css';

const App = () => {
  return (
    <div className='App'>
      <Soon />
    </div>
  )
}

export default App